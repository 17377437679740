import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  
  {
    path: '/',
    component: () => import('@/views/SignIn'), 
    meta: {
      requiresAuth: false
    },        
  },

  { 
    path: '/addlead', 
    component: () => import('@/views/AddLead'), 
    meta: {
      requiresAuth: true
    },    
  },

  { 
    path: '/addsupplier', 
    component: () => import('@/views/AddSupplier'), 
    meta: {
      requiresAuth: true
    },    
  },

  { 
    path: '/captureinvoice', 
    component: () => import('@/views/CaptureInvoice'), 
    meta: {
      requiresAuth: true
    },    
  },

  { 
    path: '/chartofaccounts', 
    component: () => import('@/views/ChartOfAccounts'), 
    meta: {
      requiresAuth: true
    },    
  },

  { 
    path: '/clients', 
    component: () => import('@/views/Clients'), 
    meta: {
      requiresAuth: true
    },    
  },

  { 
    path: '/dashboard', 
    component: () => import('@/views/Dashboard'), 
    meta: {
      requiresAuth: true
    },    
  },

  { 
    path: '/invoices', 
    component: () => import('@/views/Invoices'), 
    meta: {
      requiresAuth: true
    },    
  },

  { 
    path: '/issueinvoice', 
    component: () => import('@/views/IssueInvoice'), 
    meta: {
      requiresAuth: true
    },    
  },

  { 
    path: '/issuequote', 
    component: () => import('@/views/IssueQuote'), 
    meta: {
      requiresAuth: true
    },    
  },

  { 
    path: '/newcompany', 
    component: () => import('@/views/NewCompany'), 
    meta: {
      requiresAuth: true
    },    
  },

  { 
    path: '/paysupplier', 
    component: () => import('@/views/PaySupplier'), 
    meta: {
      requiresAuth: true
    },    
  },

  { 
    path: '/quotes', 
    component: () => import('@/views/Quotes'), 
    meta: {
      requiresAuth: true
    },    
  },

  { 
    path: '/receipts', 
    component: () => import('@/views/Receipts'), 
    meta: {
      requiresAuth: true
    },    
  },

  { 
    path: '/receivepayment', 
    component: () => import('@/views/ReceivePayment'), 
    meta: {
      requiresAuth: true
    },    
  },

  { 
    path: '/selectcompany', 
    component: () => import('@/views/SelectCompany'), 
    meta: {
      requiresAuth: true
    },    
  },

  { 
    path: '/settings', 
    component: () => import('@/views/Settings'), 
    meta: {
      requiresAuth: true
    },    
  },

  {
    path: '/signup',
    component: () => import('@/views/SignUp'), 
    meta: {
      requiresAuth: false
    },        
  },

  { 
    path: '/supplierinvoices/:supplierid', 
    component: () => import('@/views/SupplierInvoices'), 
    meta: {
      requiresAuth: true
    },    
  },

  { 
    path: '/supplierpayments/:supplierid', 
    component: () => import('@/views/SupplierPayments'), 
    meta: {
      requiresAuth: true
    },    
  },

  { 
    path: '/suppliers', 
    component: () => import('@/views/Suppliers'), 
    meta: {
      requiresAuth: true
    },    
  },
  
  { 
    path: '/updateclient/:clientid', 
    component: () => import('@/views/UpdateClient'), 
    meta: {
      requiresAuth: true
    },    
  },

  { 
    path: '/updatesupplier/:supplierid', 
    component: () => import('@/views/UpdateSupplier'), 
    meta: {
      requiresAuth: true
    },    
  },

  { 
    path: '/viewinvoice/:invoiceid', 
    component: () => import('@/views/ViewInvoice'), 
    meta: {
      requiresAuth: true
    },    
  },

  { 
    path: '/viewquote/:quoteid', 
    component: () => import('@/views/ViewQuote'), 
    meta: {
      requiresAuth: true
    },    
  },

  { 
    path: '/viewreceipt/:receiptid', 
    component: () => import('@/views/ViewReceipt'), 
    meta: {
      requiresAuth: true
    },    
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if ( to.matched.some(record => record.meta.requiresAuth) ) {
    
    // this route requires auth, check if logged in
    // if not, redirect to sign in page.
    if (!store.state.auth.authenticated) {
      next({
        path: '/',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }

  } else {
    if (to.path == '/') {
      if (store.state.auth.authenticated) {
        next({
          path: '/quotes',
          query: { redirect: to.fullPath }
        })        
      } else {
        next()
      }
    } else {
      next() // make sure to always call next()!
    }
  }
});

export default router
