<template>
  <v-app
    v-bind:class="{ frontpage: !$store.state.auth.authenticated, backdrop: $store.state.auth.authenticated }"
  >
    <v-app-bar
      v-if="$store.state.auth.authenticated"
      app
      clipped-left
    >
      <div class="d-flex align-center">

        <v-app-bar-nav-icon
          v-if="$store.state.auth.authenticated && $store.state.company"
          @click="drawer = !drawer"
        ></v-app-bar-nav-icon>

        <!--<router-link to="/dashboard">-->
          <v-img
            alt="Logo"
            src="@/assets/logo.png"
          />
        <!--</router-link>-->

      </div>

      <v-spacer></v-spacer>

      <v-menu 
        v-if="($store.state.auth.authenticated) && ($store.state.companyList.length > 0) && ($store.state.company)"
        offset-y
      >

        <template           
          v-slot:activator="{ on, attrs }"
        >

          <v-btn 
            v-if="$store.state.company"
            class="ma-2 btn" 
            v-bind="attrs" 
            v-on="on"
          >
            {{ $store.state.company.companyname }}
            <v-icon
              right
              dark
            >
              mdi-menu-down
            </v-icon>
          </v-btn>

          <v-btn 
            v-else
            class="ma-2 btn" 
            v-bind="attrs" 
            v-on="on"
          >
            Select company
            <v-icon
              right
              dark
            >
              mdi-menu-down
            </v-icon>
          </v-btn>

        </template>

        <v-list>
          <v-list-item 
            v-for="company in $store.state.companyList"
            :key="company.companyid"
            @click="selectCompany(company)"
          >
            {{ company.companyname }}
          </v-list-item>
          
          <v-divider></v-divider>

          <v-list-item
            link
            to="/newcompany"          
          >
              New Company
          </v-list-item>

        </v-list>

      </v-menu>

      <v-menu 
        v-if="$store.state.auth.authenticated"
        offset-y
      >

        <template v-slot:activator="{ on, attrs }">
          <v-btn class="ma-2 btn" v-bind="attrs" v-on="on">
            {{ $store.state.auth.user.firstname + ' ' + $store.state.auth.user.surname }}
            <v-icon
              right
              dark
            >
              mdi-menu-down
            </v-icon>            
          </v-btn>
        </template>

        <v-list>
          <v-list-item @click="signOut">
            <v-icon>
              mdi-logout
            </v-icon>            
            &nbsp;Sign Out
          </v-list-item>
        </v-list>

      </v-menu>

    </v-app-bar>

    <v-navigation-drawer 
      v-if="($store.state.auth.authenticated) && ($store.state.company)"
      v-model="drawer"
      app
      clipped
    >
      <v-list 
        nav
      >

        <!-- Dashboard -->

        <!--<v-list-item
          v-if="$store.state.company"
        >
          <v-btn
            block
            rounded
            to="/dashboard"
          >
            <v-icon left>
              mdi-view-dashboard
            </v-icon>
            Dashboard 
            <v-spacer />         
          </v-btn>
        </v-list-item>  --> 

        <!-- Quotes -->

        <v-list-item
          v-if="$store.state.company"
        >
          <v-btn
            block
            rounded
            to="/quotes"
          >
            <v-icon left style="color: #ea4335;">
              mdi-note-outline
            </v-icon>
            Quotes   
            <v-spacer />
          </v-btn>
        </v-list-item>  

        <!-- Invoices -->

        <v-list-item
          v-if="$store.state.company"
        >
          <v-btn
            block
            rounded
            to="/invoices"
          >
            <v-icon left style="color: #fb9d05;">
              mdi-note-check-outline
            </v-icon>
            Invoices  
            <v-spacer />        
          </v-btn>
        </v-list-item>  

        <!-- Receipts -->

        <v-list-item
          v-if="$store.state.company"
        >
          <v-btn
            block
            rounded
            to="/receipts"
          >
            <v-icon left style="color: #34a853;">
              mdi-note-text-outline
            </v-icon>
            Receipts  
            <v-spacer />        
          </v-btn>
        </v-list-item> 

        <!-- Clients -->

        <v-list-item
          v-if="$store.state.company"
        >
          <v-btn
            block
            rounded
            to="/clients"
          >
            <v-icon left style="color: #4285f4;">
              mdi-account-multiple
            </v-icon>
            Clients  
            <v-spacer />        
          </v-btn>
        </v-list-item> 

        <!-- Suppliers -->

        <v-list-item
          v-if="$store.state.company"
        >
          <v-btn
            block
            rounded
            to="/suppliers"
          >
            <v-icon left style="color: #885faf;">
              mdi-truck
            </v-icon>
            Suppliers  
            <v-spacer />        
          </v-btn>
        </v-list-item> 

        <!-- Chart of Accounts -->

        <v-list-item
          v-if="$store.state.company"
        >
          <v-btn
            block
            rounded
            to="/chartofaccounts"
          >
            <v-icon left>
              mdi-file-tree-outline
            </v-icon>
            Chart of Accounts  
            <v-spacer />        
          </v-btn>
        </v-list-item> 

        <!-- Settings -->

        <v-list-item
          v-if="$store.state.company"
        >
          <v-btn
            block
            rounded
            to="/settings"
          >
            <v-icon left>
              mdi-cog
            </v-icon>
            Settings  
            <v-spacer />        
          </v-btn>
        </v-list-item> 

      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view :key="$route.fullPath"></router-view>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    drawer: true
  }),

  methods: {

    selectCompany(company) {
      this.$store.commit('company', company);
      this.$router.push('/dashboard').catch(()=>{});
    },

    signOut() {
      this.$store.commit('signOut'); 
      this.$router.push('/').catch(()=>{});
    },

  },

};
</script>

<style>
  html { 
      overflow-y: auto !important;
  }
</style>

<style scoped>
  
  .frontpage {
    background: url('./assets/frontpage.jpg') no-repeat center center fixed;
    background-size: cover;
    /*background-position: center 64px;*/
  }
  
  .backdrop {
    background-color: #eeeeee;
  }

  .btn {
    text-transform: unset !important;
  }
</style>

