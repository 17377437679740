import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import { Buffer } from 'buffer';

Vue.use(Vuex)

export default new Vuex.Store({
  
  state: {
    auth: {
      authenticated: false, 
      accessToken: '',
      user: {}
    },
    company: null,
    companyList: [],
    cache: {}
  },

  getters: {
  },

  mutations: {

    company(state, company) {            
      state.company = company;
    },

    companyList(state, companyList) {            
      state.companyList = companyList;
      if (companyList.length == 1) {
        state.company = companyList[0];
      }
    },  

    setCache(state, payload) {
      if (state.company) {
        if (!state.cache['c_' + state.company.companyid]) {
          state.cache['c_' + state.company.companyid] = {};
        }
        state.cache['c_' + state.company.companyid][payload.key] = payload.value;
      }
    },

    signIn(state, accessToken) {            
      // extract payload

      const payloadBase64 = accessToken.split('.')[1].replace(/-/g, '+').replace(/_/g, '/');
      const payload = JSON.parse(Buffer.from(payloadBase64, 'base64').toString('utf8'));
      /*const payload = decodeURIComponent(window.atob(payloadBase64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));*/

      state.auth.user = payload.user;
      state.auth.accessToken = accessToken;
      state.auth.authenticated = true;
      state.company = null;
      state.companyList = [];

    },    

    signOut (state) {
      state.auth.accessToken = '';      
      state.auth.user = {};
      state.auth.authenticated = false;
    }

  },

  actions: {
  },

  modules: {
  },

  plugins: [createPersistedState({
    key: 'visionflow'
  })]
  
})
